<template>

  <BlockUI :blocked="loading>0">

    <div class="grid dashboard">

      <div class="col-12 lg:col-4">

        <div class="card">

          <Accordion :multiple="true" v-model:activeIndex="expanded" class="w-full">
            <AccordionTab v-for="(ps, k) in pedidos" :key="k">
              <template #header>
                <i :class="(k == 'Espera')?'pi pi-inbox':(k == 'Preparando'?'pi pi-upload':'pi pi-send')"></i>
                <span class="ml-2">{{ (k === 'Espera')?'Aguardando Aceite':k }}</span>
                <div class="text-right" style="margin-left: auto; order: 2;">
                  {{ Object.values(ps).length }} pedidos
                </div>
              </template>

              <div class="grid" v-if="Object.values(ps).length == 0">
                <div class="col-12">
                  Nenhum pedido encontrado.
                </div>
              </div>

              <ul id="pedidos" class="p-0 m-0 list-none" v-else>
                <li v-for="p of ps" :key="p.id" class="pb-2 mb-3 border-bottom-1 border-gray-400">
                  <div class="grid pedido" @click="pedido_mostrar(p)">
                    <div class="col-12 md:col-4">
                      <strong>
                        #{{ p.numero }}
                      </strong>
                    </div>
                    <div class="col-12 md:col-5 text-center">
                      {{ (typeof p.statusEntrega != "undefined")?p.statusEntrega.status:'' }}
                    </div>
                    <div class="col-12 md:col-3 text-right">
                      {{ p.hora }}
                    </div>
                    <div class="col-12">
                      <strong>
                        <i class="pi pi-user"></i> {{ p.usuarios.nome }}
                      </strong><br>
                      <strong v-if="typeof p.statusEntrega != 'undefined' && p.statusEntrega.entregador != null">
                        <i class="pi pi-map"></i> {{ p.statusEntrega.entregador.nome }}
                      </strong>
                    </div>
                  </div>
                </li>
              </ul>

            </AccordionTab>
          </Accordion>

        </div>

      </div>

      <div class="col-12 lg:col-8">
        <div class="card mb-0">
          <GoogleMap
            ref="mapa"
            api-key="AIzaSyC-mah_TjzO0fSC1X5AgUgj02oSKLU-tZc"
            style="width: 100%; height: calc(100vh - 181px);"
            :center="mapCenter"
            :zoom="14"
          >
            <Marker 
            v-for="(m, index) in mapMarkers"
            :key="index"
            :options="m"
            />
          </GoogleMap>
        </div>
      </div>
    
    </div>

    <Dialog v-model:visible="pedido_edit_visible" style="width: 600px" :modal="true">
      <template #header>
        <div>
          <i class="pi pi-shopping-cart text-2xl"></i> <span class="text-2xl font-semibold">Pedido</span>
        </div>
      </template>

      <PedidoView :pedido="pedido_edit" />

      <template #footer>
        <Button label="Imprimir" icon="pi pi-print" class="p-button-text" @click="pedido_imprimir(pedido_edit, $event)" />
        <Button label="Recusar" icon="pi pi-times" class="p-button-danger" v-if="pedido_edit_acoes" @click="pedido_recusar(pedido_edit, $event)" />
        <Button label="Aceitar" icon="pi pi-check" class="p-button-success" v-if="pedido_edit_acoes" @click="pedido_aceitar(pedido_edit, $event)" autofocus />
        <Button label="Pedido Pronto" icon="pi pi-check" class="p-button-info" v-if="pedido_edit_acoes_pronto" @click="pedido_pronto(pedido_edit, $event)" />
        <Button label="Pedido Entregue" icon="pi pi-check" class="p-button-success" v-if="pedido_edit_acoes_entregue" @click="pedido_entregar(pedido_edit, $event)" />
      </template>

    </Dialog>
  
  </BlockUI>

  <div class="center-spinner" v-if="loading>0">
    <ProgressSpinner />
  </div>

  <ConfirmPopup></ConfirmPopup>
  <Toast position="top-right" />

</template>

<script>

import moment from 'moment-timezone';
import firebase from 'firebase/app';
import 'firebase/database';
import { GoogleMap, Marker } from 'vue3-google-map';

import PedidoView from "./pedidos/PedidoView.vue";

var refPedidos = null;
var refPedidosFinalizados = null;
var refsPedidos = {};
var refEntregador = null;
var timerMonitor = {};

export default {
  data() {
    return {

      loading: 0,

      pedido_edit_visible: false,
      pedido_edit_acoes: false,
      pedido_edit_acoes_pronto: false,
      pedido_edit_acoes_entregue: false,
      pedido_edit: null,
      expanded: [],

      loja: null,
      pedidos: {},
      
      map: null,
      mapCenter: { lat: -16.713681718591975, lng: -49.26569591634313 },
      mapMarkers: { },

      statusPedidos: [
        "Espera",
        "Preparando",
        "Pronto",
        "Entregando",
      ]

    };
  },

  created() {
    for(const s of this.statusPedidos)
      refsPedidos[s] = {};
  },

  mounted() {

    if(this.loja != null) {
      this.clearData();
      this.clearMap();
      this.initObservers();
    }

  },

  watch: {

    '$root.user': {
      handler: function(val) {
        this.loja = this.$utils.getStdObject(val);
      },
      deep: true,
      immediate: true
    },

    loja: function(val) { 

      if(val == null)
        return;

      this.clearData();
      this.clearMap();
      this.initObservers();
    },

    pedidos: {
      handler: function(val) {

        if(typeof val != "object" || val == null)
          return;

        this.expanded = [];
        for(let i = 0; i < this.statusPedidos.length; i++) {
          const status = this.statusPedidos[i];

          if(typeof this.pedidos[status] != "undefined" && this.pedidos[status] != null) {
            if(Object.values(this.pedidos[status]).length > 0) {
              this.expanded.push(i);
            }
          }
        }

        if(typeof this.pedidos["Espera"] != "undefined" && this.pedidos["Espera"] != null) {
          if(Object.values(this.pedidos["Espera"]).length > 0) {
            this.$root.alert = true;
          } else {
            this.$root.alert = false;
          }
        } else {
          this.$root.alert = false;
        }

      },
      deep: true,
      immediate: true
    },

	},
  
  methods: {

    clearData() {
      for(const s of this.statusPedidos) {
        this.pedidos[s] = {};
        this.expanded[s] = false;
      }
    },

    clearMap() {

      if(this.loja == null)
        return;

      if(this.$root.distribuidor == null) {

        this.mapCenter = { 
          "lat": this.loja.latitude,
          "lng": this.loja.longitude
        };
      
      } else {

        this.mapCenter = this.$root.distribuidor.gps;

      }

      this.mapMarkers = {
        "loja" : {
          "title": this.loja.nome + (this.$root.distribuidor != null ? " - " + this.$root.distribuidor.nome : ""),
          "position": this.mapCenter,
          "icon": "/icos/origemcorrida.png"
        }
      };

    },

    initReferences() {

      const ref = firebase.database().ref("VipDelivery");

      if(refPedidos != null)
        refPedidos.off();

      refPedidos = ref.child('Pedidos/Lojas').child(this.loja.id);
      refPedidosFinalizados = ref.child('PedidosFinalizados/Lojas').child(this.loja.id);

    },

    initObservers() {

      const self = this;

      this.clearData();

      for(const ks in refsPedidos) {
        for(const k in refsPedidos[ks]) {
          refsPedidos[ks][k].off();
          delete refsPedidos[ks][k];
        }
      }

      this.initReferences();

      refPedidos.on('child_added', function(snapshot) {
        const val = snapshot.val();

        if(typeof val.id == "undefined") return;

        if(self.$root.distribuidor != null) {
          if(typeof val.loja.distribuidor != "string" || val.loja.distribuidor != self.$root.distribuidor.id)
            return;
        }

        refsPedidos[val.status][val.id] = snapshot.ref;
        refsPedidos[val.status][val.id].on('value', function(snapshot) {
          if(!snapshot.exists()) return;

          const val = snapshot.val();

          if((typeof val.pagamentoReservado == "boolean" && val.pagamentoReservado) || (typeof val.pagamentoEfetivado == "boolean" && val.pagamentoEfetivado)) {

            const prevStatus = self.getStatusOfPedido(val.id);

            if(prevStatus != "" && prevStatus != val.status) {
              refsPedidos[val.status][val.id] = refsPedidos[prevStatus][val.id];
              delete refsPedidos[prevStatus][val.id];
              delete self.pedidos[prevStatus][val.id];
            }

            self.pedidos[val.status][val.id] = val;

            if(val.status != "Espera")
              self.monitorar(val.id);
          
          }

        });

      });

      /////////////////////////////////////////////////////////////

      refPedidos.on('child_removed', function(snapshot) {

        const val = snapshot.val();

        if((typeof self.pedidos[val.status] != "undefined") && (typeof self.pedidos[val.status][val.id] != "undefined")) {
          delete self.pedidos[val.status][val.id];
        }

        if(typeof refsPedidos[val.status][val.id] != "undefined") {
          refsPedidos[val.status][val.id].off();
          delete refsPedidos[val.status][val.id];
        }

        if(typeof timerMonitor[val.id] != "undefined") {
          timerMonitor[val.id] = false;
        }

      });

    },

    getStatusOfPedido(pedido_id) {
      if(typeof pedido_id == "string" && pedido_id != "") {     
        for(const s of this.statusPedidos) {
          if(typeof this.pedidos[s][pedido_id] != "undefined")
            return s;
        }
      }
      return "";
    },

    pedido_mostrar(pedido) {
      this.pedido_edit = pedido;
      this.pedido_edit_acoes = (pedido.status == "Espera");
      this.pedido_edit_acoes_pronto = (pedido.status == "Preparando");
      this.pedido_edit_acoes_entregue = (pedido.status == "Pronto");
      this.pedido_edit_visible = true;

      this.acompanhar_entrega(pedido);
    },

    async pedido_aceitar(_pedido) {
      
      this.loading++;

      const self = this;
      let pedido = this.$utils.getStdObject(_pedido);

      const erroFn = function(message, error, context) {

        console.log("ERRO ACEITAR PEDIDO:");
        console.log(error);

        context.$toast.add({
          severity: "error",
          summary: "ERRO!",
          detail: message,
          life: 5000,
        });

      };

      pedido.status = "Preparando";
      pedido.timeStampAceite = parseInt(moment().tz("America/Sao_Paulo").format("YYYYMMDDHHmmss"));

      /* #region ChamandoEntregadorMega */

      if(typeof pedido.tipoEntrega != "undefined" && pedido.tipoEntrega.id.substr(0, 6) == "vipapp") {

        try {

          let endereco = [pedido.usuarios.lougradouro];

          if(typeof pedido.usuarios.numeroCasa != "undefined")
            endereco.push(pedido.usuarios.numeroCasa);

          if(typeof pedido.usuarios.complemento != "undefined" && pedido.usuarios.complemento.trim() != "")
            endereco.push(pedido.usuarios.complemento);

          if(typeof pedido.usuarios.bairro != "undefined")
            endereco.push(pedido.usuarios.bairro);

          if(typeof pedido.usuarios.cidade != "undefined")
            endereco.push(pedido.usuarios.cidade);

          if(typeof pedido.usuarios.UF != "undefined")
            endereco.push(pedido.usuarios.UF);

          let chamado = {

            "pedido": pedido.id,
            
            "cliente": pedido.usuarios.nome,
            "endereco": endereco.join(', '),
            "cep": pedido.usuarios.cep,
            "gps": {
              "lat": parseFloat(pedido.usuarios.latitude),
              "lng": parseFloat(pedido.usuarios.longitude)
            },

            "retorno": false,
            "receita": false,

            "roteamento": false,
            "web": false,
            "vipDelivery": true,

            "novoChamado": true,

          };

          if(this.$root.distribuidor != null) {
            chamado.distribuidor = this.$root.distribuidor.id;
          }

          const response = await this.$apiMega.post('/chamado', chamado);

          let dados = response.data;

          if(typeof dados.success != "boolean" || !dados.success) { 

            if(Array.isArray(dados)) {
              dados.error = {
                message: dados[0].message
              };
            }

            erroFn("Erro ao Chamar o Entregador! Código: PPMCC. " + dados.error.message , dados, self);
            return;

          } else {

            pedido.entrega = {
              timeStamp: parseInt(dados.data.replace(/[^0-9]/g, "")),
              chamado: dados.chamado,
              entrega: dados.entrega,
            };

          }

        } catch (error) {

          erroFn("Erro ao Chamar o Entregador! Código: PPMCC. Verifique o LOG.", error, self);
          return;

        }
        
      }

      /* #endregion */

      let err = await refPedidos.child(pedido.id).set(pedido);

      if(!err) {

        const ref = firebase.database().ref("VipDelivery");

        err = await ref.child("Pedidos/Usuarios").child(pedido.usuarios.id).child(pedido.id).set(pedido);

        if(!err) {

          self.$toast.add({
            severity: "success",
            summary: "Sucesso!",
            detail: "Pedido Aceito!",
            life: 3000,
          });

          self.pedido_edit_visible = false;

          self.acompanhar_entrega(pedido);

        } else {
          erroFn("Erro ao Aceitar o Pedido! Código: PPUS. Verifique o LOG.", err, self);
        }

      } else {
        erroFn("Erro ao Aceitar o Pedido! Código: PPLS. Verifique o LOG.", err, self);
      }

      this.loading--;

    },

    pedido_recusar(_pedido, event) {

      const self = this;
      let pedido = this.$utils.getStdObject(_pedido);

      const erroFn = function(message, error, context) {

        console.log("ERRO RECUSA PEDIDO:");
        console.log(error);

        context.$toast.add({
          severity: "error",
          summary: "ERRO!",
          detail: message,
          life: 5000,
        });

      };

      this.$confirm.require({
        target: event.currentTarget,
        message: 'Tem certeza que deseja RECUSAR este Pedido?',
        icon: 'pi pi-exclamation-triangle',
        acceptClass: 'p-button-danger',
        accept: async () => {

          self.loading++;

          pedido.status = "Recusado";
          pedido.timeStampRecusado = parseInt(moment().tz("America/Sao_Paulo").format("YYYYMMDDHHmmss"));

          let err = await refPedidosFinalizados.child(pedido.id).set(pedido);

          if(!err) {

            err = await refPedidos.child(pedido.id).remove();

            if(!err) {

              const ref = firebase.database().ref("VipDelivery");

              err = await ref.child("PedidosFinalizados/Usuarios").child(pedido.usuarios.id).child(pedido.id).set(pedido);

              if(!err) {

                err = await ref.child("Pedidos/Usuarios").child(pedido.usuarios.id).child(pedido.id).remove();

                if(!err) {

                  // TODO PROCESSO DE CANCELAMENTO PAGAMENTO
                  if(false == true && Array.isArray(pedido.retornoGerencia) && pedido.retornoGerencia.length > 0) {

                    try {

                      const dataPost = {
                        "body": {
                          "custom_id": pedido.id,
                          "notification_url": "https://api.vipapp.mm.tec.br/delivery/notificacao"
                        },
                        "params": {
                          "id": pedido.retornoGerencia[0].identifiers['charge_id']
                        }
                      };

                      const response = await this.$api.post("/cancelar", dataPost);
                      const data = response.data;

                      if(data.success) {

                        self.$toast.add({
                          severity: "success",
                          summary: "Sucesso!",
                          detail: "Pedido Recusado!",
                          life: 3000,
                        });

                        self.pedido_edit_visible = false;

                      } else {
                        erroFn("Erro ao Recusar o Pedido! Código: PPCP. Verifique o LOG.", data, self);
                      }

                    } catch (error) {
                      erroFn("Erro ao Recusar o Pedido! Código: PPCP. Verifique o LOG.", error, self);
                    }

                  } else {

                    self.$toast.add({
                      severity: "success",
                      summary: "Sucesso!",
                      detail: "Pedido Recusado!",
                      life: 3000,
                    });

                    self.pedido_edit_visible = false;

                  }

                } else {
                  erroFn("Erro ao Recusar o Pedido! Código: PPUR. Verifique o LOG.", err, self);
                }

              } else {
                erroFn("Erro ao Recusar o Pedido! Código: PFUS. Verifique o LOG.", err, self);
              }

            } else {
              erroFn("Erro ao Recusar o Pedido! Código: PPLR. Verifique o LOG.", err, self);
            }

          } else {
            erroFn("Erro ao Recusar o Pedido! Código: PFLS. Verifique o LOG.", err, self);
          }

          self.loading--;

        }

      });

    },

    pedido_imprimir(pedido) {

      window.open(
        "/" + this.$router.resolve({
          name: 'impressao',
          params: {
            id: pedido.id,
          },
        }).href
      );

    },

    async monitorar(pedido) {

      if(typeof timerMonitor[pedido] == "undefined")
        timerMonitor[pedido] = true;
      else if(timerMonitor[pedido] == false) {
        delete timerMonitor[pedido];
        return;
      }

      try {
        // TODO OTIMIZAR
        const response = await this.$apiMega.get('/verificarEntrega?pedido=' + pedido);
        const data = response.data;

        if(data.success) {
          
          const status = this.getStatusOfPedido(pedido);

          let statusMega = "";
          switch(data.status) {
            case "ORDER_AWAITING_DELIVERYMAN": {
              statusMega = "Buscando Entregador";
              break;
            }
            case "ORDER_DELIVERY_IN_PROGRESS": {
              statusMega = "Retirando Pedido";
              break;
            }
            case "ORDER_DELIVERED": {
              statusMega = "Pedido Entregue";
              break;
            }
          }

          this.pedidos[status][pedido].statusEntrega = {
            status: statusMega,
            entregador: data.entregador,
            data: data.data
          };

          if(data.status == "ORDER_DELIVERED") {
            delete timerMonitor[pedido];
            return;
          }

        } else {
          delete timerMonitor[pedido];
          return;
        }
      
      } catch (e) {

        console.log("!! ERRO NO MONITORAMENTO: PEDIDO " + pedido + " !!");
        console.log(e);

      }

      await new Promise(r => setTimeout(r, 2000));

      this.monitorar(pedido);

    },

    acompanhar_entrega(pedido) {

      const self = this;

      if(refEntregador != null) {
        refEntregador.off();
        refEntregador = null;
      }

      if(typeof this.mapMarkers.destino != "undefined")
        delete this.mapMarkers.destino;

      if(typeof this.mapMarkers.entregador != "undefined")
        delete this.mapMarkers.entregador;

      this.mapMarkers.destino = {
        "title": pedido.usuarios.nome,
        "position": {
          "lat": pedido.usuarios.latitude,
          "lng": pedido.usuarios.longitude
        },
        "icon": "/icos/destinocorrida.png"
      };

      if(typeof pedido.statusEntrega != "undefined" && pedido.statusEntrega.entregador != null) {

        this.mapMarkers.entregador = {
          "title": pedido.statusEntrega.entregador.nome,
          "position": {
            "lat": 0,
            "lng": 0
          },
          "icon": "/icos/iconemegawebmotoqueiro.png"
        };

        refEntregador = firebase.database().ref('Empresas/' + this.loja.logistica + "/LocalMotoboy/" + pedido.statusEntrega.entregador.id + "/l");
        refEntregador.on("value", function(snapshot) {
          const pos = snapshot.val();

          let entregador = self.$utils.getStdObject(self.mapMarkers.entregador);

          entregador.position = {
            lat: pos[0],
            lng: pos[1]
          };

          self.mapMarkers.entregador = entregador;

          self.centralizar_mapa();

        });

      }

    },

    async pedido_pronto(_pedido) {

      this.loading++;

      const self = this;
      let pedido = this.$utils.getStdObject(_pedido);

      const erroFn = function(message, error, context) {

        console.log("ERRO MARCAR PEDIDO COMO PRONTO:");
        console.log(error);

        context.$toast.add({
          severity: "error",
          summary: "ERRO!",
          detail: message,
          life: 5000,
        });

      };

      pedido.status = "Pronto";
      pedido.timeStampPronto = parseInt(moment().tz("America/Sao_Paulo").format("YYYYMMDDHHmmss"));

      let err = await refPedidos.child(pedido.id).update({
        status: "Pronto",
        timeStampPronto: pedido.timeStampPronto
      });

      if(!err) {

        const ref = firebase.database().ref("VipDelivery");

        err = await ref.child("Pedidos/Usuarios").child(pedido.usuarios.id).child(pedido.id).update({
          status: "Pronto",
          timeStampPronto: pedido.timeStampPronto
        });

        if(!err) {

          self.$toast.add({
            severity: "success",
            summary: "Sucesso!",
            detail: "Pedido marcado como Pronto!",
            life: 3000,
          });

          self.pedido_edit_visible = false;

        } else {
          erroFn("Erro ao marcar o Pedido como Pronto! Código: PMUS. Verifique o LOG.", err, self);
        }

      } else {
        erroFn("Erro ao marcar o Pedido como Pronto! Código: PMLS. Verifique o LOG.", err, self);
      }

      this.loading--;

    },

    async pedido_entregar(_pedido) {

      this.loading++;

      const self = this;
      let pedido = this.$utils.getStdObject(_pedido);

      const erroFn = function(message, error, context) {

        console.log("ERRO MARCAR PEDIDO COMO ENTREGUE:");
        console.log(error);

        context.$toast.add({
          severity: "error",
          summary: "ERRO!",
          detail: message,
          life: 5000,
        });

      };

      pedido.status = "Finalizado";
      pedido.timeStampFinal = parseInt(moment().tz("America/Sao_Paulo").format("YYYYMMDDHHmmss"));

      let err = await refPedidosFinalizados.child(pedido.id).set(pedido);

      if(!err) {

        const ref = firebase.database().ref("VipDelivery");

        err = await ref.child('PedidosFinalizados/Usuarios').child(pedido.usuarios.id).child(pedido.id).set(pedido);

        if(!err) {

          await ref.child("Pedidos/Usuarios").child(pedido.usuarios.id).child(pedido.id).remove();
          await refPedidos.child(pedido.id).remove();

          self.$toast.add({
            severity: "success",
            summary: "Sucesso!",
            detail: "Pedido marcado como Entregue!",
            life: 3000,
          });

          self.pedido_edit_visible = false;

        } else {

          erroFn("Erro ao marcar o Pedido como Entregue! Código: PEUS. Verifique o LOG.", err, self);

        }

      } else {

        erroFn("Erro ao marcar o Pedido como Entregue! Código: PELS. Verifique o LOG.", err, self);

      }

      this.loading--;

    },

    centralizar_mapa() {

      let bounds = new this.$refs.mapa.api.LatLngBounds();
      for(const m of Object.values(this.mapMarkers)) {
        bounds.extend(m.position);
      }
      
      this.$refs.mapa.map.fitBounds(bounds);
      this.$refs.mapa.map.panToBounds(bounds);

    }

  },
  components: { 
    GoogleMap, 
    Marker,
    PedidoView
  },

};
</script>
<style scoped>

  :deep(.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link) {
    background: #c82a03;
    color: white;
    border-color: #c82a03;
  }

  :deep(.p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link) {
    background: #c82a03;
    border-color: #c82a03;
    color: #ffffff;
  }

  :deep(.p-accordion .p-accordion-header .p-accordion-header-link) {
    background: #cf6a51;
    color: white;
  }

  :deep(.p-accordion .p-accordion-content), :deep(.p-accordion .p-accordion-header .p-accordion-header-link) {
    border-color: #c82a03;
  }

  :deep(#pedidos li:last-child) {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
    border-bottom: 0;
  }

  :deep(.pedido) {
    cursor: pointer;
  }

</style>